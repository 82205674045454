import { Div, Text, Title } from 'components'
import Layout from 'layouts/en'
import Faq from 'pages/faq.en'
import React from 'react'

export default () => {
  return (
    <Layout p={5}>
      <Div mb={[4,6]} pt={8} textAlign={'center'}>
        <Title mb={[2,4]} fontSize={[7,9]}>We are here to help</Title>
        <Text mb={[5,7]} fontSize={[6,8]}>Our response time is usually 1 hour</Text>
      </Div>

      <Div mx={[-36,0]}>
        <Faq justContent/>
      </Div>
    </Layout>
  )
}
